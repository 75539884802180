const EMOTIONS = [
        {
          "emotion": "afraid",
          "verses": ["Matthew 5:5", "Titus 3:2", "Psalm 37:11", "Matthew 11:29"]
        },
        {
          "emotion": "rejected",
          "verses": ["John 15:18-21", "Psalm 27:10", "Psalm 41:7", "John 1:11"]
        },
        {
          "emotion": "helpless",
          "verses": ["Romans 8:26-27", "Mark 11:24", "Hebrews 4:15", "Proverbs 3:5"]
        },
        {
          "emotion": "insecure",
          "verses": ["Philippians 4:6-9", "1 Samuel 16:7", "1 John 1:9", "James 5:16"]
        },
        {
          "emotion": "confused",
          "verses": ["1 Corinthians 14:33", "2 Timothy 2:7", "1 John 4:1", "1 Peter 5:8"]
        },
        {
          "emotion": "anxious",
          "verses": ["Philippians 4:6-7", "1 Peter 5:7", "John 14:27", "Matthew 6:25-34"]
        },
        {
          "emotion": "exhausted",
          "verses": ["Matthew 11:28-29", "Psalm 4:8", "Jeremiah 31:25"]
        },
        {
          "emotion": "bored",
          "verses": ["Colossians 3:23-24", "Proverbs 19:15", "Matthew 28:18-20", "2 Timothy 2:22"]
        },
        {
          "emotion": "guilty",
          "verses": ["Romans 3:23-24", "1 John 1:9", "Isaiah 54:4", "Acts 8:22"]
        },
        {
          "emotion": "ashamed",
          "verses": ["Matthew 5:14-16", "Romans 10:9", "Psalm 103:1-22", "John 3:18"]
        },
        {
          "emotion": "lonely",
          "verses": ["Isaiah 41:10", "Matthew 28:20", "Deuteronomy 31:6", "Psalm 27:10"]
        },
        {
          "emotion": "sad",
          "verses": ["Psalm 34:17-18", "Deuteronomy 31:8", "Philippians 4:8", "John 16:33"]  
        },
        {
          "emotion": "hurt",
          "verses": ["Mark 11:25", "1 Peter 5:10", "Mathew 6:14-15", "Numbers 23:19"],
        },
        {
          "emotion": "angry",
          "verses": ["Romans 13:4", "Psalm 46:1", "Matthew 5:38-39", "1 Timothy 5:8"]
        },
        {
          "emotion": "critical",
          "verses": ["Ephesians 4:29", "Matthew 7:1-5", "James 4:11-12", "1 Peter 4:8"]
        },
        {
          "emotion": "frustrated",
          "verses": ["John 16:33", "Isaiah 41:10", "Galatians 6:9", "Psalm 34:18"]
        },
        {
          "emotion": "annoyed",
          "verses": ["Proverbs 27:3", "Ephesians 4:1-3", "Proverbs 12:16", "Luke 6:27-36"]
        },
        {
          "emotion": "jealous",
          "verses": ["James 3:16", "1 Corinthians 3:3", "Exodus 20:17", "Romans 12:2"]
        },        
        {
          "emotion": "respected",
          "verses": ["Matthew 7:12", "Exodus 20:12", "Philippians 2:3", "2 Corinthians 10:12-16"]
        },
        {
          "emotion": "confident",
          "verses": ["Proverbs 3:26", "1 John 3:20-21", "Hebrews 13:6", "Proverbs 28:26"]
        },
        {
          "emotion": "worthy",
          "verses": ["Matthew 22:8", "Galatians 2:20", "1 Corinthians 6:11", "Romans 5:8"]
        },
        {
          "emotion": "proud",
          "verses": ["Proverbs 11:2", "Psalm 10:4", "1 John 2:16", "Galatians 6:3"]
        },
        {
          "emotion": "strong",
          "verses": ["Matthew 10:31", "1 Timothy 4:8", "Psalm 139:14", "1 Peter 1:18-19"]
        },
        {
          "emotion": "important",
          "verses": ["1 Corinthians 12:14", "James 1:22", "John 3:16", "Romans 3:23"]
        },        
        {
          "emotion": "creative",
          "verses": ["Exodus 35:30-35", "Ephesians 2:10", "Genesis 1:1", "Proverbs 22:29"]
        },
        {
          "emotion": "happy",
          "verses": ["Proverbs 17:22", "2 Corinthians 9:7", "James 1:17", "Proverbs 15:15"]
        },
        {
          "emotion": "inspired",
          "verses": ["John 16:13", "Joshua 1:9", "Matthew 6:31-34", "Proverbs 3:5-6"]
        },
        {
          "emotion": "energetic",
          "verses": ["Matthew 6:33", "Colossians 1:29", "Luke 21:36", "Acts 3:19-21"]
        },
        {
          "emotion": "hopeful",
          "verses": ["Romans 8:24-25", "Jeremiah 29:11", "Romans 15:13", "Hebrews 11:1"]
        },
        {
          "emotion": "grateful",
          "verses": ["1 Thessalonians 5:18", "Hebrews 12:28", "Psalm 100:3-4", "Acts 17:28"]
        },        
        {
          "emotion": "loving",
          "verses": ["John 3:16", "1 Corinthians 13:4-8", "John 13:34-35", "Mark 12:29-31"]
        },
        {
          "emotion": "content",
          "verses": ["Hebrews 13:5", "1 Timothy 6:6-8", "2 Corinthians 12:10", "Psalm 37:3-5"]
        },
        {
          "emotion": "thoughtful",
          "verses": ["Philippians 4:8", "Ephesians 4:22-24", "Proverbs 17:22", "Colossians 3:2"]
        },
        {
          "emotion": "trusting",
          "verses": ["Proverbs 3:5", "Jeremiah 29:11", "Proverbs 3", "Psalm 13:5"]
        },
        {
          "emotion": "virtue",
          "verses": ["Philippians 4:8", "Colossians 3:12", "2 Peter 1:5-8", "Ephesians 4:25-32"]
        },
        {
          "emotion": "peaceful",
          "verses": ["Hebrews 13:4", "1 Peter 3:7-12", "1 John 4:7-8", "1 Corinthians 13:1-13"]
        }        
  ];  

  export default EMOTIONS;